<template>
  <div class="contact">
    <Head :headdata="headdata"></Head>
    <div class="contactMain htmlContent" v-html="htmlContent">
      
    </div>
  </div>
</template>

<script>
import Head from "@/components/header.vue";
export default {
  data() {
    return {
      headdata: this.$t("m.jobHeaddata1"),
      htmlContent:'',
    };
  },
  components: {
    Head,
  },
  created() {
    this.getData();
  },
  updated() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  methods: {
    getData() {
      this.axios
        .get(this.GLOBAL.serverSrc + "/wc/contract") // 真实环境中，后端会根据参数group返回新的图片数组，这里我用一个惊呆json文件模拟
        .then((res) => {
          console.log(res);
          if(res.data.code==1){
             this.htmlContent=res.data.data.contact;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  height: 100%;
  width: 100%;
  .contactMain {
    min-height: 1100px;
    width: 600px;
    margin: 0 auto;
    padding-top: 78px;
    padding-bottom: 150px;
  }
}
@media (max-width: 768px) { 
   .twoview .twoviewMain > div > div {
     padding-left:0 !important;
     padding-right:0 !important;
   }
  }
</style>